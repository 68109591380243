var speakers = [
	{
		id: 10,
		name: 'Da Hongfei',
		title: 'Founder, NEO',
        details: 'Founder of NEO & Onchain, key opinion leader of the blockchain industry, serial entrepreneur. Da Hongfei is blockchain advisor to a handful of banks, brokers, and clearing houses. In 2014, he founded NEO, a blockchain to register, transfer, and exchange digital assets, and led it to become the benchmark in the Chinese blockchain community.',
		image: 'speakers/10.jpg',
	},
	{
		id: 20,
		name: 'Erik Zhang',
		title: 'Founder, NEO',
        details: 'Erik is NEO founder and core developer, author to dBFT consensus mechanism, expert on blockchain technology and computer security and a certified information system auditor (CISA). He was employed by Shanda Games and www.huobi.com where he was engaged in information security and R&D in digital currency.',
		image: 'speakers/20.jpg',
	},
    {
        id: 30,
        name: 'Zhao Chen',
        title: 'General Manager, NGD',
        details: 'Hong Kong University postgraduate. Rich cross-discipline experiences in banking and the international financial service industry. Dedicated to inter-industry communication, building the developer community, refining business models, and integrating blockchain use cases with technical expertise and understanding.',
        image: 'speakers/30.jpg',
    },
    {
        id: 40,
        name: 'Drew Gude',
        title: 'Managing Director, Microsoft Digital World-wide',
        details: 'Drew Gude is Managing Director of Microsoft Digital World-wide. He is responsible for strategy for the Digital Transformation business, working with Fortune 500 customers in helping them transform their businesses using technology. He is a veteran of Microsoft, having spent many years in roles spanning engineering, marketing and sales. He is an accomplished public speaker, and speaks and writes on digital business transformation and innovative business models. He is a srong proponent of computing at the edge.',
        image: 'speakers/40.jpg',
    },
    {
        id: 50,
        name: 'Miha Kralj',
        title: 'Managing Director, Accenture',
        details: 'Miha Kralj helps customers realize the full value of cloud adoption by creating strategic transformation road maps and guiding organizational change towards modern IT. He works with senior IT executives to their align business and technology strategies, and following industry best practices for cloud selection and consumption. In his 25 years of IT strategy experience, Miha has become a board-certified enterprise IT architect, and has earned an industry-wide reputation as a technology expert.',
        image: 'speakers/50.jpg',
    },
    {
        id: 500,
        name: 'Parimal Deshpande',
        title: 'Founding Member & Director of Marketing, Xbox Game Pass',
        details: "Parimal Deshpande has 15 years of broad experience in all aspects of business and marketing (business planning, business development, strategy, digital marketing, analytics/decision science, developer/designer/partner ecosystem development, media agencies, brand strategy, AR/PR, and product management). He is currently driving marketing and strategy for Xbox Store. Expertise and passion for next-gen devices and services in delivering great consumer experiences in digital media (games, video, TV, music, apps).",
        image: "speakers/500.jpg",
    },  
    {
        id: 60,
        name: 'Dr. Joseph Williams',
        title: 'Governor Jay Inslee‘s ICT Industry Sector Lead',
        details: 'Joseph Williams is a tech industry and higher education veteran with deep roots in Washington.  Dr. Williams is a former executive from Microsoft and Sun Microsystems, the former dean of the School of Business, Government, and Economics at Seattle Pacific University, and an active angel investor and industry analyst who is a veteran of four startups with two exits. Joseph has his Ph.D. in Business from the University of Texas at Austin. He has authored three books and dozens of articles on the tech industry, and is an IEEE editor.  Dr. Williams is a frequent speaker at industry events and a self-professed geek.',
        image: 'speakers/60.jpg',
    },
    {
        id: 70,
        name: 'Pablo Junco',
        title: 'Director, Worldwide Apps Solutions Strategy, Microsoft',
        details: 'Pablo Junco is a Director, Business Program Management at Microsoft Corporation. With over 20 years of experience in IT industry, Pablo is passionate about exploring how technologies such as IoT and Blockchain can be used to change people’s lives. Pablo’s areas of focus include helping customers to understand the value of disruptive technologies in their business, identify business patterns and to build end-to-end programs to assist Microsoft professional services across the globe in their mission of empowering enterprise customers to reach their digital aspirations. Pablo is an Iasa Certified IT Architect – Professional (CITA-P) and holds a Bachelor of Science from the University of Southern Mississippi (Institution of Spain).',
        image: 'speakers/70.jpg',
    },
    {
        id: 80,
        name: 'Peter Lin',
        title: 'R&D Director, NGD',
        details: 'Director of R&D, NEO Global Development (NGD), one of the early NEO developers.He has 6 years of experience in internet technology development, engaged in the development of blockchain applications in the public chain and alliance chain.',
        image: 'speakers/80.jpg',
    },
    {
        id: 90,
        name: 'Waldemar Scherer',
        title: 'Head of Enterprise Blockchain',
        details: 'Waldemar is the Co-Founder and is leading the Enterprise Blockchain practice of Swisscom Blockchain. As blockchain expert since more than 4 years, he applied the technology successfully within several projects for multinational corporates. He has a background as former big 4 manager on one of the largest finance transformation programs and has experience of 8 years in the finance sector as advisor.',
        image: 'speakers/90-1.jpg',
    },
    {
        id: 490,
        name: 'Ted Neward',
        title: 'Computational Philosopher',
        details: "Ted Neward is an industry professional of twenty years' experience. Ted Neward is sometimes referred to as 'The Dude of Software'. He's comfortable answering to this title, as well as a few others. He's familiar with more programming languages than most people knew existed, and hasn't found one yet that he couldn't turn into a 'mission-critical' application when asked. He speaks at conferences all over the world and writes regularly for a variety of publications across the Java, .NET, and other ecosystems. He currently resides in the Pacific Northwest with his wife, two sons, dog, four cats, eight laptops, seven tablets, nine phones, and a rather large utility bill.",
        image: "speakers/490.jpg",
    },
    {
        id: 110,
        name: 'Dr. Zhiniang Peng',
        title: 'Security Researcher, Qihoo 360',
        details: 'Dr. Zhiniang Peng is a security researcher at Qihoo 360 core security. He has more than 10 years of experience in both offensive and defensive security and published many academic papers in the field of information security. Dr. Peng has designed several security products in data security and discovered several critical vulnerabilities in various fields. His current research interests include software security, blockchain security and applied cryptography.',
        image: 'speakers/110.jpg',
    },
    {
        id: 120,
        name: 'Harry Pierson',
        title: 'Program Manager for Xlang, Microsoft',
        details: 'He was also prevously the Program Manager for IronPython. He has been at Microsoft for over 20 years;  he spent the Windows 8 and 8.1 product cycles as a program manager on the team that built Windows Runtime. After four years as a PM, he got tired of writing specs so he switched to a developer role. He spent time with the Midori research project working on C# for Systems. He then rejoined the core Windows team, working on the cross-platform plumbing that powers SmartGlass.  He has also been tangentally involved with the C++/WinRT project and many other initiatives inside Microsoft. He tweets @devhawk.',
        image: 'speakers/120.jpg',
    },
    {
        id: 140,
        name: 'Dr. Chris Berg',
        title: 'Senior Research fellow, RMIT Blockchain Innovation Hub',
        details: 'Chris Berg is a Senior Research Fellow at the RMIT Blockchain Innovation Hub, a Founding Board Member of the Worldwide Blockchain Innovation Association, a Senior Fellow with the Institute of Public Affairs, and an Academic Fellow with the Australian Taxpayers’ Alliance. Dr Berg is the author of seven books, and one of the global leaders in understanding how technological change effects the economy and individual liberties. His next books Cryptodemocracy (with Darcy Allen and Aaron Lane) and How to Understand the Blockchain Economy (with Sinclair Davidson and Jason Potts) are forthcoming in 2019.',
        image: 'speakers/140.jpg',
    },
    {
        id: 150,
        name: 'John Wang',
        title: 'Ecosystem Growth Manager, NGD',
        details: 'John Wang is the Eco Growth Manager of NGD, he has many years of experience in supply chain management consulting, and several times successful entrepreneurial experiences, he is currently responsible for NEO ecosystem development.',
        image: 'speakers/150.jpg',
    },
    {
        id: 160,
        name: 'Tamar Salant',
        title: 'Ecosystem Growth Manager, NGD',
        details: 'Tamar is Eco System growth manager based in NGD office Shanghai. Tamar comes from a data analyst background and has been working in high-tech companies in various sectors before moving to China in 2015. Tamar has been focused on Blockchain since 2011 and has been studying and researching the field since then.',
        image: 'speakers/160.jpg',
    },
    {
        id: 170,
        name: 'Sergei Liubich',
        title: 'Co-founder and Managing Partner, NSPCC',
        details: 'Sergei is managing the core project of NEO SPCC, distributed decentralized storage platform, being a PhD Candidate in Reliability of Distributed Computer Systems at ITMO University (Russia). He worked on practical data science problems at Samsung Electronics (Seoul R&D Center) and ETH Zurich and then got interested in secure data storing and sharing. Sergei has M.Sc. degree in Computer & Electrical Engineering (Seoul National University) and M.Sc. degree in Applied Mathematics & Informatics (St. Petersburg State University).',
        image: 'speakers/170.jpg',
    },
    {
        id: 171,
        name: 'Anatoly Bogatyrev',
        title: 'Co-founder and Technical Director, NSPCC',
        details: 'Anatoly is a technical guru of NEO SPCC. He has a PhD in Reliability of Cluster Computer Systems and M.Sc. degree in Computer Networks & Telecommunications (ITMO University). He has been working on distributed data storage problems for about 10 years. Anatoly is a former senior software engineer in data storage infrastructure at Dell-EMC.',
        image: 'speakers/171.jpg',
    },
    {
        id: 180,
        name: 'Igor Machado Coelho',
        title: 'Co-founder, NeoResearch',
        details: 'Igor Machado Coelho received the B.S. in Computing from Federal University of Ouro Preto, Brazil, the M.S. and PhD degree in Algorithms and Optimization in 2015 at the Computing Institute of Fluminense Federal University, Niterói, Brazil. He is currently Professor in Computer Science Department at State University of Rio de Janeiro. He is one of the creators and maintainers of the OptFrame project, a framework for combinatorial optimization and co-founder of distinct open-source projects and initiatives, including CombView language. His research interests include the resolution of challenging combinatorial optimization problems in fields of Vehicle Routing, Open-Pit Mining Operational Planning, Smart Grid, and the development of novel algorithms for emerging computing architectures and decentralized systems.',
        image: 'speakers/180.jpg',
    },
    {
        id: 181,
        name: 'Vitor Nazário Coelho',
        title: 'Co-founder, NeoResearch',
        details: 'Vitor Nazario Coelho is a researcher engaged in scientific and social causes. Technician in Electronic Instrumentation, Control and Automation Engineering, PhD degree in Electrical Engineering of Federal University of Minas Gerais. Doctor in Electrical Engineering, in the field of Computational Intelligence, by the Pós-Graduação em Engenharia Elétrica (PPGEE) the Universidade Federal de Minas Gerais (UFMG), finishing his PhD in 2 years, with 25 years old, without attending Master studies. During the PhD, Vitor did an exchange period at the University of Sheffield (England), in partnership with the Rolls Royce Technology Center in Control and Systems Engineering, and at ORT Braude College of Engineering (Israel), as a Marie Curie researcher for the European Commission as part of the Seventh Framework Program (FP7). Thesis awarded as the best one in the Engineering area in the year of 2016 of UFMG. Between 2016 and 2018, he was a Post-Doctorate researcher in partnership with the Universidade Federal Fluminense, supported by the outstanding program PDR-10/FAPERJ program. Currently, he acts as an autonomous developer, researcher, investor and partner of different projects.',
        image: 'speakers/181.jpg',
    },
    {
        id: 190,
        name: 'Liu Yongxin',
        title: 'Founder, NEL and NNS ',
        details: 'Founder of NEO chinese developer community NEL, Founder of  NEO Name Service.',
        image: 'speakers/190.jpg',
    },
    {
        id: 210,
        name: 'Dr. Junda Liu',
        title: 'Co-founder, Celer Network',
        details: 'Dr. Junda Liu received his Ph.D. from UC Berkeley, advised by Prof. Scott Shenker. He was the first to propose and develop DAG based routing to achieve nanosecond network recovery (1000x improvement over state of art). Dr. Liu joined Google in 2011 to apply his pioneer research to Google‘s global infrastructure. As the tech lead, he developed a dynamic datacenter topology capable of 1000 terabit/s bisection bandwidth and interconnecting more than 1 million nodes. In 2014, Dr. Liu became a founding member of Project Fi (Google’s innovative mobile service). He was the tech lead for seamless carrier switching, and oversaw Fi from a concept to a $100M+/year business within 2 years. He was also the Android Tech Lead for carrier services, which run on more than 1.5B devices. Dr. Liu holds 6 US patents and published numerous papers in top conferences. He received BS and MS from Tsinghua University.',
        image: 'speakers/210.jpg',
    },
    {
        id: 230,
        name: 'Fabio C.Canesin',
        title: 'Co-founder, CoZ and Nash',
        details: 'Fabio Canesin is a co-founder of Nash, a revolutionary decentralized exchange aiming to make blockchain technology accessible to a wide public. Fabio holds a BSc in Mechanical Engineering and an MSc in Computational Science. Before working on Nash full time, he spent much of his professional life as a research engineer at Schlumberger, developing solutions for the oil and gas industry. Fabio is well known within the blockchain scene also as a founder of the City of Zion (CoZ) open-source community, which brought the Nash co-founders together and continues to develop key infrastructure for the NEO blockchain.',
        image: 'speakers/230.jpg',
    },
    {
        id: 240,
        name: 'Stephen Hyduchak',
        title: 'CEO and Co-founder, Bridge Protocol',
        details: 'Stephen is passionate about know-your-customer (KYC)  processes and reducing costs with new technology. Bridge is a RegTech solution to ensure safety and compliance for all types of businesses. Through use of blockchain, AI, facial recognition and big data, Bridge offers standardization for digital IDs across the world.',
        image: 'speakers/240.jpg',
    },
    {
        id: 241,
        name: 'Alex Guba',
        title: 'CTO, Bridge Protocol',
        details: 'Alex leads Bridge in development of the tech stack and architecture of the Bridge Protocol. He comes with many years of startup experience and leadership roles in the medical industry and data compliance.',
        image: 'speakers/241.jpg',
    },
    {
        id: 250,
        name: 'Alan Fong',
        title: 'Co-Founder, Moonlight.io | NEO - NEP-5 Co-Author',
        details: 'Formerly at the University of Michigan researching energy storage applications through intensive computer modeling and simulation. Currently a co-founder and developer with City of Zion, an international, open source group focusing on work related to the NEO blockchain, and Moonlight.io',
        image: 'speakers/250.jpg',
    },
    {
        id: 260,
        name: 'Sean Chen',
        title: 'Founder, Blacat',
        details: 'Founder of BlaCat, he is proficient in Internet development technologies such as 3D engine and Web3D engine. Since 2009 he has had abundant entrepreneurial experiences in the video gaming industry completing the R&D of multiple game products that have reached monthly revenues in the millions of RMB and accumulated revenue exceeded 600 million RMB.',
        image: 'speakers/260.jpg',
    },
    {
        id: 272,
        name: 'Rayman Yang',
        title: 'Lead Architect and Senior Blockchain Advisor, NeoWorld',
        details: 'Rayman is one of the leading figures in the application of blockchain technology in the game industry in China and was instrumental in designing the IT and blockchain structure of NeoWorld, the virtual world. Rayman has been researching the application of blockchain in the gamification of large projects since early 2017 and led the NeoLand project. In addition to his rich experience in blockchain, he has over 10 years of experience as CTO or chief architect in several large scale games and other projects. Rayman was educated in National University of Singapore with computer science background and started his career in Microsoft.',
        image: 'speakers/272.jpg',
    },
    {
        id: 280,
        name: 'Mark Jeffrey',
        title: 'Co-Founder and CEO, Guardian Circle',
        details: 'Mark Jeffrey is an award-winning serial entrepreneur and author with Harper Collins. Most recently, Mark co-founded Guardian Circle and $GUARD token community emergency response network. He is also an early pioneer of blockchain technology, having published BITCOIN EXPLAINED SIMPLY (2013) and THE CASE FOR BITCOIN (2016).',
        image: 'speakers/280.jpg',
    },
    {
        id: 290,
        name: 'Joe Zhou',
        title: 'Co-founder, Jarvisplus',
        details: 'Jarvisplus project co-founder. Before join Jarvisplus, he was founder of MUHE network, co-founder and technical consultant of CarBlock. Being an edge technology pursuer, Joe led MUHE to become one of the first Chinese smart phone app developers (2010) and one of the first Html5 game developers (2011). Joe also helped to incubate and design the CarBlock Blockchain project (2017).',
        image: 'speakers/290.jpg',
    },
    {
        id: 300,
        name: 'Eric Wang',
        title: 'Co-founder, Archon',
        details: 'Eric is a co-founder of Archon and an experienced blockchain investor. At Archon, he primarily handles business development, investor relations and research. He is a frequently invited speaker in blockchain events, and was a Chemistry PhD student at Stanford and holds a MS from Stanford a BS from UCLA.',
        image: 'speakers/300.jpg',
    },
    {
        id: 310,
        name: 'Chris Qi',
        title: 'CTO, Alchemint',
        details: '10+ years of experience in information technology construction and management in the financial industry. Served as general manager of Sinosoft E-Commerce Division, senior consultant of Financial Division in Neusoft Group and CTO of Harmonia Capital. In 2017, partnered with Bitpoint, a Japanese licensee, Chris sets up a digital asset exchange in mainland China.',
        image: 'speakers/310.jpg',
    },
    {
        id: 320,
        name: 'Tyler Adams',
        title: 'Founder, CoZ and Moonlight',
        details: 'Tyler is a complex systems enthusiast and author of the NEP-5 token standard.  He is also a Co-Founder of both CoZ and Moonlight.  His background is in dynamical systems modeling and analytical project management.',
        image: 'speakers/320.jpg',
    },
    {
        id: 330,
        name: 'Bryan Myint',
        title: 'Managing Director, Republic Crypto and Partner @Taureon',
        details: 'Bryan has been researching, mining and investing in cryptocurrencies since 2016. He is also a founding partner at Taureon Capital. Prior experience includes being a strategy consultant for big pharma clients, project manager at early stage startups, and product engineer for medical devices. Bryan graduated from UCLA with a degree in Bioengineering in 2010.',
        image: 'speakers/330.jpg',
    },
    {
        id: 340,
        name: 'Douwe van de Ruit',
        title: 'Business Owner, DLT Enabled Solutions, KPN',
        details: 'As a senior business developer with a solid technical background, Douwe has a visionary look at trends, new concepts and changes in the field of digital transformation and the telecom industry. His focus is on value creation based on new technology and / or concepts in the digital world. ',
        image: 'speakers/340.jpg',
    },
    {
        id: 350,
        name: 'Brett Rhodes',
        title: 'Editor, NEO News Today',
        details: 'More commonly known as Edge within the NEO community, Brett has a background in writing and is currently an editor of NEO News Today.',
        image: 'speakers/350.jpg',
    },
    {
        id: 360,
        name: 'Rudy(Ruotian) Rong',
        title: 'CEO, Magic Cube Interactive',
        details: 'Rudy is a Wharton and USC alumni. Founding Magic Cube in 2016, Rudy is creating a decentralized gaming publishing platform connecting people around the world. Magic Cube empowers users to become Eco-Providers at the same time. Rudy was also elected as Forbes 30 Under 30 in 2017.',
        image: 'speakers/360.jpg',
    },
    {
        id: 370,
        name: 'Fu Xiang',
        title: 'Senior System Engineer, Onchain',
        details: 'Core developer of Ontology and DNA. Have worked in Chinese Academy of Sciences and NetScout and is very experienced in digital signal processing, wireless network security monitoring and cloud computing development, Now focus on the scaling of blockchain and distributed storage.',
        image: 'speakers/370.jpg',
    },
    {
        id: 380,
        name: 'Alex Knight',
        title: 'Lead Designer, O3',
        details: 'With over 15 years experience, working as a freelance UX designer, company co-founder and product designer at various start-ups all over the world, Alex now leads design at O3 for all products and marketing, across multiple platforms.',
        image: 'speakers/380.jpg',
    },
    {
        id: 391,
        name: 'Dr. Ronghui Gu',
        title: 'Certik, CEO',
        details: 'Ph.D, Yale University; Associate Professor, Columbia University',
        image: 'speakers/391.jpg',
    },
    {
        id: 400,
        name: 'Dr. Guil. Sperb Machado',
        title: 'Co-founder, neow3j and AxLabs',
        details: 'Guil is a researcher, software engineer, and passionate about open source software. He holds a Ph.D. degree in Computer Science from the University of Zürich. Besides being an academic researcher for several years, he acquired industry experience in large projects at Swisscom, Hewlett-Packard, and several start-ups. His core expertise lies in architecting highly scalable systems and leading software engineering teams, ultimately outputting reliable software products for millions of users. He is involved in blockchain topics since 2013, dealing with infrastructure of full nodes, smart contracts, building libraries/SDKs, and ICO platforms. Guil is the co-founder of neow3j (https://neow3j.io), which is a Java and Android library to interact with the NEO blockchain, as well as the founder of AxLabs (https://axlabs.com).',
        image: 'speakers/400.jpg',
    },
    {
        id: 410,
        name: 'Alex DiCarlo',
        title: 'Founder, NEO Tracker',
        details: "After receiving his Bachelors of Computer Science from University of Illinois, Alex was an intern at Facebook London, later joining full time, where he developed data ingestion pipelines to improve the quality of places, events and music pages on Facebook. Later he transitioned to Facebook Seattle and began working on Facebook's framework for creating and maintaining data analysis pipelines used by thousands of engineers and data analysts. The blockchain explosion last spring piqued Alex's interest and in order to learn more about Blockchain he decided to build NEO Tracker and NEO•ONE",
        image: 'speakers/410.jpg',
    },
    {
        id: 420,
        name: 'Dr. Brad Mattson',
        title: 'Founder, NEO Health',
        details: "NEO Health founder Brad Mattson, MD is a practicing radiologist. He is a 2013 graduate of the Michigan State University College of Human Medicine and a 2018 graduate of the diagnostic radiology program at Baystate Medical Center in Springfield, MA. He and NEO Health co-founders Nikhil Madhuripan, MD, Kaiser Hussain, MD, Ling Wu, PhD, and Laurent David are building an online medical encyclopedia with evidence-based guidelines and access to the world's leading medical journals.",
        image: "speakers/420.jpg",
    },
    {
        id: 430,
        name: 'Maxwell Lasky',
        title: 'neon-wallet maintainer, neon-wallet',
        details: "Max loves the mountains, crypto and writing code. Graduating with a degree in political science from the State University of New York, he pursued software engineering full time after moving to Colorado in 2012. He has been contributing to the NEO community for roughly a year and a half and looks forward to the future of the smart economy.",
        image: "speakers/430.jpg",
    },
    {
        id: 440,
        name: 'Fernando Díaz Toledano',
        title: 'Co-founder，Red4Sec Cybersecurity',
        details: "Fernando Diaz Toledano is CO-Founder of Red4Sec Cybersecurity, collaborates reporting major security flaws and vulnerabilities in BugBounty programs. Fernando is a developer with more than 12 years of experience, expert in security, blockchain and smart contracts, member of City of Zion and NEO Core Developer",
        image: "speakers/440.jpg",
    },

    {
        id: 450,
        name: 'Diego Jurado Pallarés',
        title: 'Co-founder，Red4Sec Cybersecurity',
        details: "Diego Jurado is CO-Founder of Red4Sec Cybersecurity and member of the Red Team & Ethical Hacking Team at Telefonica. Passionate about cybersecurity and blockchain technologies, Diego is a Computer Engineer and holds an International Master in cybersecurity and cyberdefense. Professional certified in offensive security, active researcher in bugbounty programs and winner of multiple CTF Challenges.",
        image: "speakers/450.jpg",
    },
    {
        id: 460,
        name: 'Nolan Bauerle',
        title: 'Director of Research，Coindesk',
        details: "Nolan was trained as a lawyer and worked for several Parliamentary committees in Canada. This work included a long term study of the 2008 financial crisis followed by a long term study of the global anti-money laundering and terrorism finance regime. This was fertile ground for a year and half long study of cryptocurrencies, starting in 2013. Nolan helped convince the committee to take up the study, selected witnesses, and drafted the final report. Simultaneous to the report’s tabling in Parliament, Nolan arranged for the report to be hashed into bitcoin's blockchain (block #361625 ). Since joining CoinDesk in 2016, Nolan has enjoyed a bird’s eye view of the industry, has remained an expert in the progress and challenges of various use-cases, and has contributed to the advancement of the formal discipline of cryptoeconomics.",
        image: "speakers/460.jpg",
    },
    {
        id: 470,
        name: 'Drey Ng',
        title: 'Chief Product Officer, Liquefy',
        details: "Drey is the Chief Product Officer of Liquefy, the Partner and Head of Research of BlackHorse Ventures (BHV), also the Head of Research of Orichal Partners. Graduated from the University of Hong Kong with a bachelor degree in Quantitative Finance, he is awarded with the Machine Learning Certificate by Stanford University and Cousera.",
        image: "speakers/470.jpg",
    },
    {
        id: 480,
        name: 'Alek Tan',
        title: 'CEO, InnoDT,Inc.',
        details: "Alek has over 10 years experience in finance and operations. Extensive experience in large-scale financial planning, management, and corporate strategy at Fortune 500 companies. He founded successful startups and won the second place in TechCrunch Disrupt Battlefield competition.",
        image: "speakers/480.jpg",
    },
    {
        id: 510,
        name: 'Jonathan Meiri',
        title: 'CEO and Founder, Barrel Protocol',
        details: "Jonathan Meiri is the CEO and Founder of Barrel Protocol, a start-up focusing on automat consumer data compliance using smart contracts. Jonathan was the CEO and founder of Superfly Insights. Prior to that, he was the senior product manager for PayPal and eBay. Jonathan got his MBA from Insead.",
        image: "speakers/510.jpg",
    },
    {
        id: 520,
        name: 'Angela Tong',
        title: 'Partner of Jinse Finance',
        details: "As a seasoned member of media industry, she has nearly a decade of experience. Mrs.Tong follows and reports on bitcoin since 2011. In 2017, Mrs.Tong decided leaving Netease Tech to assume the position of editor in chief in Jinse Finance and began to focus on blockchain and cryptos reporting. In the meantime, as a member of editorial board she helped write the best-seller blockchain book Blockchain+ listed on JD.com. Since August 2018, Angela Tong began to focus on developing global markets as COO of CoinTime. So far, she has brought CoinTime into many countries and regions of the world include US, Korea, Japan, Singapore, Russia, Indonesia and Middle East, continuously producing premium contents to users all over the world.",
        image: "speakers/520.jpg",
    }, 
    {
        id: 530,
        name: 'Nicholas Merten',
        title: 'Founder, DataDash YouTube channel',
        details: "Nicholas Merten is the founder of DataDash, the largest cryptocurrency YouTube channel with over 315,000 subscribers. Being an international speaker, thought-leader, and crypto analyst in the space, he’s utilized his over seven years of experience in traditional markets to understand the potential of cryptocurrencies. As a skeptic of modern finance, he’s not only built an impressive set of knowledge on global markets, but he’s also currently leading a self-funded effort, known as Project Genesis, which aims to fix many of the key issues of the financial world and bring cryptocurrencies into the mainstream.",
        image: "speakers/530.jpg",
    },
    {
        id: 540,
        name: 'Matthew Beedham',
        title: 'Blockchain and cryptocurrency writer, TNW',
        details: "Matt Beedham is a staff writer at The Next Web's specialist blockchain and cryptocurrency publication, Hard Fork. ",
        image: "speakers/540.jpg",
    },
    {
        id: 550,
        name: 'Cole Petersen',
        title: 'Journalist, NewsBTC',
        details: "Cole Petersen is a cryptocurrency and blockchain enthusiast who writes freelance for multiple news outlets, including NewsBTC. In addition to covering market and industry-related news, he also helped lead the PR campaign for Covesting -- an early-stage cryptocurrency trading platform. Cole is currently an undergraduate at the University of California, Irvine, and ultimately plans on attending business school.",
        image: "speakers/550.jpg",
    },
    {
        id: 560,
        name: 'Longfei Wang',
        title: 'Software developer, NGD',
        details: "Longfei has a master’s degree in Applied Information Technologies from Towson University, USA. Since his graduation, Longfei has been working as a software developer for Premier, USAC and Wanxiang Blockchain Labs. He has been dedicated to research on blockchain technology and previously contributed to the creation of international blockchain standards (ISO/TC 307).",
        image: "speakers/560.jpg",
    },               
];

export default speakers;
